<template lang="pug">
    form(@submit.prevent="handleSubmit()")
        h1.text-title.ta-center.text-primary Acesse
        .p-inputgroup.my-1(:class="{ 'form-group--error': form.submitted && $v.form.model.nomeusuario.$error }")
            span.p-inputgroup-addon <i class="pi pi-user"></i>
            InputText(type='text' placeholder='Digite seu usuário' v-model='$v.form.model.nomeusuario.$model')
        .feedback--errors(v-if='form.submitted && $v.form.model.nomeusuario.$error')
            .form-message--error(v-if="!$v.form.model.nomeusuario.required") Usuário é obrigatório.

        .p-inputgroup.my-1(:class="{ 'form-group--error': form.submitted && $v.form.model.senha.$error }")
            span.p-inputgroup-addon <i class="pi pi-lock"></i>
            InputText(type='password' placeholder="Digite sua senha" v-model='$v.form.model.senha.$model')
        .feedback--errors(v-if='form.submitted && $v.form.model.senha.$error')
            .form-message--error(v-if="!$v.form.model.senha.required") Senha é obrigatória.
            .form-message--error(v-if="!$v.form.model.senha.minLength") Senha deve conter pelo menos 6 caracteres.

        .p-inputgroup.my-1
            ProgressBar.progressbar--button(v-if='form.waiting' mode='indeterminate')
            .w-100(v-else)
                Button.w-100( label="Entrar" type='submit')
                .ta-right.mt-1
                    a.text-esqsuasenha.text-secondary(@click='$router.push("/usuario/auth/recuperar-senha")') Esqueceu sua senha?
</template>

<script>
    import InputText from 'primevue/inputtext'
    import Button from 'primevue/button'
    import InputMask from 'primevue/inputmask'
    import ProgressBar from 'primevue/progressbar'

    import { Usuario } from './../../middleware'
    import { required, minLength } from 'vuelidate/lib/validators'
    export default {
        components: { InputText, InputMask, Button, ProgressBar },
        data () {
            return {
                form: {
                    model: {
                        nomeusuario: '',
                        senha: ''
                    },
                    submitted: false,
                    waiting: false
                }
            }
        },
        validations: {
            form: {
                model: {
                    nomeusuario: { required },
                    senha: { required, minLength: minLength(6) }
                }
            }
        },
        methods: {
            handleSubmit () {
                this.form.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) return 0

                this.form.waiting = true
                Usuario.login(this.form.model.nomeusuario, this.form.model.senha).then(usuario => {
                    if (usuario) {

                        if (usuario.estabelecimentos.length > 1) {
                            usuario.selecionarEstabelecimento = true
                            localStorage.setItem('usuario', JSON.stringify(usuario))
                        }

                        if (usuario.menu) {
                            let starterRoute = ''

                            if (Object.values(usuario.menu)[0].children)
                                starterRoute = Object.values(usuario.menu)[0].children[0]
                            else starterRoute = Object.keys(usuario.menu)[0]

                            this.$router.push({ name: starterRoute })
                        } else {
                            this.$toast.error('Usuário sem menu configurado', { duration: 3000 })
                        }
                        this.form.waiting = false
                    } else {
                        this.$toast.error('Credenciais inválidas, acesso negado', { duration: 3000 })
                        this.form.waiting = false
                    }
                })
            }
        }
    }
</script>
